import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import introGreenCheck from "../img/green-check.svg"
import careerPathsLevelingImage from "../img/leveling.svg"
import Layout from "../components/Layout"
import CTAButton from "../components/CTAButton"
import SimpleOnboardingSection from "../components/SimpleOnboardingSection";
import HashtagHidden from "../components/HashtagHidden";

// eslint-disable-next-line
export const EmployeeWorkforceAnalyticsPageTemplate = ({ intro, careerPaths, closing, kamsaApproach }) => {
    const renderSubText = ({ subText }) => {
        return subText ? (
            <div className="tags">
                {
                    subText.map((text, index) => (
                        <span key={index} className="tag">
                            { text.description }
                        </span>
                    ))
                }
            </div>
        ) : null
    }

    const renderBulletPoints = (content) => {
        return (
            <div className="section section--gray has-text-centered is-rounded-border">
                <div className="columns is-mobile">
                    <div className="column kamsa-approach">
                        <div className="block">
                            <p className="is-size-5 has-text-weight-bold">{ content.subheader }</p>
                        </div>
                        <div className="block">
                            <ul>
                                {
                                    content.bullets.map((bullet, index) => (
                                        <li key={index} className="mt-5">
                                            <p className="is-size-5">{ bullet.description }</p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <section className="section is-small no-padding-bottom">
                <div className="container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Employee Workforce and Analytics - Kamsa</title>
                        <meta property="og:title" content="Employee Workforce and Analytics - Kamsa" />
                        <meta name="description" content="Kamsa is a compensation management system that combines global salary data & expert support from compensation consultants for simplified compensation planning." />
                    </Helmet>
                </div>
            </section>
            <section className="section section--gradient is-small mb-6">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-7">
                            <div className="block">
                                <h4 className="is-h4">{ intro.header }</h4>
                                <h1 className="title is-h1">{ intro.subheader }</h1>
                            </div>
                            <div className="block">
                                <h3 className="subtitle is-h3">{ intro.body }</h3>
                            </div>
                            <div className="block">
                                <div className="openers--container">
                                    { intro.openers.map((value, index) =>
                                        <div key={index} className="columns is-mobile openers--row">
                                            <div className="column is-narrow">
                                                <img className="openers--icon" src={introGreenCheck} alt="Green checkmark" />
                                            </div>
                                            <div className="column is-flex is-align-items-center">
                                                <p className="openers--description">{value.description}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="is-pulled-left" >
                                <CTAButton link={intro.buttonLink} text={intro.buttonText} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-large" style={{ paddingBottom: '6rem', paddingTop: '6rem' }}>
                <div className="container">
                    <div className="block has-text-centered">
                        <h2 className="title is-2 has-text-weight-bold">{ kamsaApproach.header }</h2>
                    </div>
                    <div className="block has-text-centered mb-6">
                        <h3 className="subtitle is-h3">{ kamsaApproach.subheader }</h3>
                    </div>
                    <div className="columns is-multiline is-vcentered">
                        {
                            kamsaApproach.content.map((section, index) => {
                                return (
                                    <div key={index} className="column is-4 my-4">
                                        <div className="block has-text-centered bubble-header"
                                             style={{ background: section.headerBackgroundColor }}
                                        >
                                            <h1 className="subtitle has-text-weight-bold bubble-text">{ section.header }</h1>
                                        </div>
                                        <div className="block">
                                            { renderBulletPoints(section) }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section id={careerPaths.anchorText} className="section section--violet is-large" style={{ paddingBottom: '6rem', paddingTop: '6rem' }}>
                <div className="container">
                    <div className="box">
                        <div className="block">
                            <h2 className="title is-2 has-text-weight-bold">{ careerPaths.header }</h2>
                        </div>
                        <div className="block">
                            <h1 className="subtitle is-size-5">{ careerPaths.subheader }</h1>
                        </div>
                        <div className="columns is-vcentered">
                            <div className="column is-6 has-text-centered">
                                <div className="block">
                                    <img className="img-400" src={careerPathsLevelingImage} alt="user data"/>
                                </div>
                                <CTAButton link={careerPaths.buttonLink} text={careerPaths.buttonText} />
                            </div>
                            <div className="column">
                                <div className="container">
                                    {
                                        careerPaths.tags.map((tag, index) => {
                                            return (
                                                <div key={index} className="box mr-64">
                                                    <div className="block">
                                                        <p>{ tag.text }</p>
                                                    </div>
                                                    { renderSubText(tag) }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--gradient is-medium no-padding-bottom">
                <SimpleOnboardingSection
                    title={closing.title}
                    text={closing.text}
                    buttonLink={closing.buttonLink}
                    buttonText={closing.buttonText}
                    icons={closing.icons}
                />
            </section>
        </>
    )
}

EmployeeWorkforceAnalyticsPageTemplate.propTypes = {
    intro: PropTypes.shape({
        header: PropTypes.string,
        subheader: PropTypes.string,
        body: PropTypes.string,
        buttonLink: PropTypes.string,
        buttonText: PropTypes.string,
        openers: PropTypes.arrayOf(PropTypes.shape({
            description: PropTypes.string,
        })),
      }),
    careerPaths: PropTypes.shape({
        anchorText: PropTypes.string,
        header: PropTypes.string,
        subheader: PropTypes.string,
        buttonLink: PropTypes.string,
        buttonText: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            subText: PropTypes.arrayOf(PropTypes.shape({
                description: PropTypes.string,
            })),
        }))
    }),
    kamsaApproach: PropTypes.shape({
        header: PropTypes.string,
        subheader: PropTypes.string,
        content: PropTypes.arrayOf(PropTypes.shape({
            header: PropTypes.string,
            headerBackgroundColor: PropTypes.string,
            subheader: PropTypes.string,
            bullets: PropTypes.arrayOf(PropTypes.shape({
                description: PropTypes.string,
            })),
        }))
    }),
    closing: PropTypes.shape({
        icons: PropTypes.array,
    })
}

const EmployeeWorkforceAnalyticsPage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark
  const { sharedClosing } = pageContext

  return (
    <Layout>
      <EmployeeWorkforceAnalyticsPageTemplate
        intro={frontmatter.ewaPageIntro}
        careerPaths={frontmatter.ewaPageCareerPaths}
        closing={sharedClosing}
        kamsaApproach={frontmatter.ewaPageKamsaApproach}
      />
      <HashtagHidden hashtags={frontmatter.ewaPageHashtags} />
    </Layout>
  );
};

EmployeeWorkforceAnalyticsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EmployeeWorkforceAnalyticsPage

export const employeeWorkforceAnalyticsPageQuery = graphql`
  query EmployeeWorkforceAnalyticsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ewaPageIntro {
            header
            subheader
            body
            buttonLink
            buttonText
            openers {
                description
            }
        }
        ewaPageKamsaApproach {
            header
            subheader
            content {
                header
                headerBackgroundColor
                subheader
                bullets {
                    description
                }
            }
        }
        ewaPageCareerPaths {
            anchorText
            header
            subheader
            buttonLink
            buttonText
            tags {
                text
                subText {
                    description
                }
            }
        }
        ewaPageHashtags {
            title
        }
      }
    }
  }
`;